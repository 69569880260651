import { PropsWithChildren } from 'react'
import { ChainId, DAppProvider, Config } from '@usedapp/core'
import { getDefaultProvider } from 'ethers'

type Props = PropsWithChildren<{
  smartContractChainId: ChainId
  localhostMulticallAddress?: string
}>

const config = (
  readOnlyChainId: ChainId,
  localhostMulticallAddress?: string
): Config => ({
  readOnlyChainId,
  readOnlyUrls: {
    // [ChainId.Localhost]: 'http://127.0.0.1:8545/',
    [ChainId.Rinkeby]: getDefaultProvider('rinkeby'),
    [ChainId.Mainnet]: getDefaultProvider('mainnet'),
  },
  multicallAddresses: localhostMulticallAddress
    ? {
        [ChainId.Localhost]: localhostMulticallAddress,
      }
    : undefined,
})

const DAppProviderWithConfig: React.FC<Props> = ({
  children,
  smartContractChainId,
  localhostMulticallAddress,
}) => {
  return (
    <DAppProvider
      config={config(smartContractChainId, localhostMulticallAddress)}
    >
      {children}
    </DAppProvider>
  )
}

export default DAppProviderWithConfig
