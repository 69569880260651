import { SxProps } from '@mui/material/styles'
import { ListItemText } from '@mui/material'

import { colors } from 'src/styles'
import * as S from './styled'

export type NavItemType = {
  title: string
  path: string
  isExternalLink?: boolean
  icon?: React.ReactNode
  info?: string
  disabled?: boolean
}

type Props = {
  item: NavItemType
  active: (path: string) => boolean
}

const NavItem: React.FC<Props> = ({ item, active }) => {
  const isActiveRoot = active(item.path)

  const { title, path, icon, info } = item

  const activeRootStyle: SxProps = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    background: colors.gradient,
    opacity: 1,
  }

  const wrapInLink = (element: React.ReactNode) => {
    return item.disabled ? (
      <S.StyledLinkDisabled>{element}</S.StyledLinkDisabled>
    ) : item.isExternalLink ? (
      <S.StyledLinkExternal
        href={path}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {element}
      </S.StyledLinkExternal>
    ) : (
      <S.StyledLink to={path}>{element}</S.StyledLink>
    )
  }

  return wrapInLink(
    <S.StyledListItemButton sx={isActiveRoot ? activeRootStyle : undefined}>
      <S.StyledListItemIcon>{icon && icon}</S.StyledListItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </S.StyledListItemButton>
  )
}

export default NavItem
