import { createTheme } from '@mui/material/styles'
import { typography } from '../typography'
import ComponentsOverrides from './overrides'

const darkTheme = createTheme({
  typography,
  shape: { borderRadius: 8 },
  palette: {
    mode: 'dark',
    text: { primary: '#fff' },
    background: { default: '#242636', paper: '#242636' },
    info: {
      main: '#484CFC',
    },
    primary: {
      main: '#FA4078', // pink
    },
    secondary: {
      main: '#484CFC', // blue
    },
  },
  components: ComponentsOverrides(),
})

export { darkTheme }

export type AppStyledThemeType = typeof darkTheme
