import DocsGirl from './docs_girl.webp'
import Dog from './dog.webp'
import RocketGirl from './rocket_girl.webp'
// others
import CollectionPlaceholder from './collection_placeholder.webp'

export const publicImages = {
  Sally3D: {
    DocsGirl,
    Dog,
    RocketGirl,
  },
  // others
  CollectionPlaceholder,
}
