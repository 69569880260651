import { Contract } from '@ethersproject/contracts'

import { NFTContract } from '@artiffine/hardhat-types/src/contracts/NFTContract'
import { NFTContract__factory } from '@artiffine/hardhat-types/src/factories/contracts/NFTContract__factory'

// ABI types
const ERC20Interface = NFTContract__factory.createInterface()

// contract singleton
let contracts: { [key: string]: NFTContract } = {}

export const construct = (smartContractAddress: string) => {
  if (contracts[smartContractAddress]) {
    return contracts[smartContractAddress]
  }
  const createdContract = new Contract(
    smartContractAddress,
    ERC20Interface
  ) as NFTContract
  contracts[smartContractAddress] = createdContract
  return createdContract
}

export const contractFactory = {
  construct,
}

export type ContractInterface = NFTContract
