import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { routes } from 'src/constants/routes'
import AppRoute from 'src/containers/AppRoute'
import Footer from 'src/components/Footer'

// pages
import DashboardPage from 'src/pages/DashboardPage'
import Page404 from 'src/pages/Page404'
import PageSoonOnline from 'src/pages/PageSoonOnline'
import StyleguidePage from 'src/pages/StyleguidePage'

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          {/* Public Pages */}
          <Route
            path={routes.index}
            element={
              <AppRoute>
                <DashboardPage />
              </AppRoute>
            }
          />
          <Route
            path={'/styleguide'}
            element={
              <AppRoute>
                <StyleguidePage />
              </AppRoute>
            }
          />
          <Route path='*' element={<NoMatch />} />
        </Routes>
      </Router>
      <Footer />
    </React.Fragment>
  )
}

// 404 route
export const NoMatch: React.FC = () => {
  return <PageSoonOnline />
}

export default App
