import HomeIcon from '@mui/icons-material/Home'
import InsightsIcon from '@mui/icons-material/Insights'
import BookIcon from '@mui/icons-material/Book'
import TwitterIcon from '@mui/icons-material/Twitter'
import { useTranslation } from 'react-i18next'

import { routes } from 'src/constants/routes'
import { NavItemType } from './NavSection/NavItem'

const useNavConfig = () => {
  const [t] = useTranslation()

  const navConfig: NavItemType[] = [
    {
      title: t('sidebar.myCollections'),
      path: routes.index,
      icon: <HomeIcon />,
    },
    {
      title: t('sidebar.analytics'),
      path: '/#',
      icon: <InsightsIcon />,
      disabled: true,
    },
  ]

  const communityNavConfig: NavItemType[] = [
    {
      title: 'Twitter',
      path: routes.external.twitter,
      isExternalLink: true,
      icon: <TwitterIcon />,
    },
    {
      title: t('sidebar.blog'),
      path: routes.external.blog,
      isExternalLink: true,
      icon: <BookIcon />,
    },
  ]

  return { navConfig, communityNavConfig }
}

export { useNavConfig }
