import { useState, PropsWithChildren } from 'react'
import { Container } from '@mui/material'

import ErrorBoundary from 'src/containers/ErrorBoundary'

import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

import * as S from './styled'

type Props = PropsWithChildren<{}>

const Layout: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <S.Wrapper>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <S.MainWrapper>
        <S.MobileOnly>Artiffine BETA is optimized for desktop</S.MobileOnly>
        <Container maxWidth='xl'>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Container>
      </S.MainWrapper>
    </S.Wrapper>
  )
}

export default Layout
