import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { ListItemIcon, ListItemButton } from '@mui/material'

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  height: 54,
  position: 'relative',
  textTransform: 'capitalize',
  color: `${theme.palette.text.primary} !important`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: 8,
  opacity: 0.6,
}))

export const StyledListItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const StyledLinkDisabled = styled('a')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  fontWeight: 400,
  position: 'relative',

  '&:after': {
    content: '"Coming soon"',
    position: 'absolute',
    // left: 72,
    // top: 40,
    left: 160,
    top: 18,
    fontSize: 10,
    display: 'none',
    opacity: 0.6,
    background: theme.palette.info.main,
    padding: '4px 8px',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
  },
  '&:hover::after': {
    display: 'block',
  },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  fontWeight: 400,
}))

export const StyledLinkExternal = styled('a')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  fontWeight: 400,
}))
