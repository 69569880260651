import { useState, useEffect, useCallback } from 'react'
import {
  getLocalStorage,
  setLocalStorage,
  LocalStorageKeys,
  removeLocalStorage,
} from '../utils/localStorageUtils'
import { isServer } from '../utils/systemUtils'

type AppendTuple<T extends unknown[], I> = [...T, I]

function useLocalPersistedState<T>(key: LocalStorageKeys, serverSideValue?: T) {
  let initState: T | undefined = serverSideValue
  if (!isServer()) {
    try {
      const parsed = JSON.parse(getLocalStorage(key)) as T
      initState = parsed
    } catch (e) {
      // do not set the state
      initState = undefined
    }
  }

  const usedState = useState<T | undefined>(initState)
  const [state, setState] = usedState

  const refreshState = useCallback(() => {
    try {
      const parsed = JSON.parse(getLocalStorage(key)) as T
      setState(parsed)
    } catch (e) {
      // do not set the state
    }
  }, [key, setState])

  // read the init state
  useEffect(() => {
    refreshState()
  }, [refreshState])

  // persist any change in the state
  useEffect(() => {
    if (state === undefined) {
      removeLocalStorage(key)
    } else {
      setLocalStorage(key, JSON.stringify(state))
    }
  }, [state, key])

  return [...usedState, refreshState] as AppendTuple<
    typeof usedState,
    typeof refreshState
  >
}

export { useLocalPersistedState }
