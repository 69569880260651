import { useCallback } from 'react'

import { useFetchHook } from 'src/hooks/useFetchHook'
import { ICollection, useCollectionsClient } from 'src/api'

export const useAllCollectionsData = () => {
  const { data, setData, loading, setLoading, error, setError, getError } =
    useFetchHook<ICollection[]>()
  const client = useCollectionsClient()

  const load = useCallback(async () => {
    setError(undefined)
    setLoading(true)
    try {
      const { data } = await client.getAll()

      setData(data)
    } catch (e) {
      setError(getError(e as any)) // TODO: remove any
      setData(undefined)
    }
    setLoading(false)
  }, [getError, setData, setError, setLoading, client])

  return { data, loading, error, load }
}
