import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { routes, isRoutePrivate } from 'src/constants/routes'
import ErrorBoundary from 'src/containers/ErrorBoundary'

type Props = {
  children: React.ReactNode
}

const AppRoute: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation()

  const isAuthenticated = true // TODO add state

  const isPrivate = !pathname ? false : isRoutePrivate(pathname)

  return isPrivate && !isAuthenticated ? (
    <Navigate to={routes.index} />
  ) : (
    <ErrorBoundary>{children}</ErrorBoundary>
  )
}

export default AppRoute
