import * as S from './styled'

const Button = S.Button
const ButtonLink = S.ButtonLink
const ButtonSmall = S.ButtonSmall
const ButtonSmallLink = S.ButtonSmallLink

export default Button

export { ButtonLink, ButtonSmall, ButtonSmallLink }
