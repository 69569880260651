import React from 'react'
import { formatUnits } from '@ethersproject/units'
import { useGasPrice } from '@usedapp/core'

import * as S from './styled'

type Props = {}

const GasPrice: React.FC<Props> = () => {
  const gasPrice = useGasPrice()

  return (
    <S.Wrap>
      <S.Label>Gas price</S.Label>
      <S.Value>
        {gasPrice !== undefined
          ? `${formatUnits(gasPrice, 'gwei').split('.')[0]} gwei`
          : '---'}
      </S.Value>
    </S.Wrap>
  )
}

export default GasPrice
