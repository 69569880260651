import { styled } from '@mui/material/styles'

export const Wrap = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 4),
  borderRadius: theme.shape.borderRadius,
  height: '50.2px',
  marginRight: theme.spacing(4),
}))

export const Label = styled('span')(({ theme }) => ({
  ...(theme.typography.caption as any),
  fontWeight: theme.typography.fontWeightBold,
  opacity: 0.8,
  width: '100%',
}))

export const Value = styled('span')(({ theme }) => ({
  ...(theme.typography.caption as any),
  fontWeight: theme.typography.fontWeightBold,
  width: '100%',
}))
