import React from 'react'
import { Box, Button, Paper, Stack, SxProps, Typography } from '@mui/material'

type Props = {
  title: string
  text: string | React.ReactNode
  button?: {
    text: string
    onClick: () => void
  }
  image?: React.ReactNode
  sx?: SxProps
}

const Banner: React.FC<Props> = ({ title, text, image, button, sx }) => {
  return (
    <Paper sx={{ px: { xs: 4, md: 8 }, py: 4, ...sx }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography variant='h1'>{title}</Typography>
          <Box
            sx={{
              mt: { xs: 3, md: 5 },
              mb: button ? { xs: 3, md: 5 } : undefined,
            }}
          >
            <Typography variant='body2'>{text}</Typography>
          </Box>
          {button && (
            <Button variant='contained' onClick={button.onClick}>
              {button.text}
            </Button>
          )}
        </Box>
        {image && (
          <Box
            flex={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            {image}
          </Box>
        )}
      </Stack>
    </Paper>
  )
}

export default Banner
