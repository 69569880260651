import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Drawer, Typography } from '@mui/material'

import { ButtonSmallLink } from 'src/components/Button'
import { useResponsive } from 'src/hooks/useResponsive'
import Scrollbar from 'src/components/Scrollbar'
import Logo from 'src/components/Logo'
import { publicImages } from 'src/static/images'
import { routes } from 'src/constants/routes'

import NavSection from './NavSection'
import { useNavConfig } from './navConfig'
import * as S from './styled'

type Props = {
  isOpenSidebar: boolean
  onCloseSidebar: () => void
}

const DashboardNavbar: React.FC<Props> = ({
  isOpenSidebar,
  onCloseSidebar,
}) => {
  const { pathname } = useLocation()
  const { communityNavConfig, navConfig } = useNavConfig()

  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 4,
          mb: 3,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo />
      </Box>

      <NavSection title={'Menu'} navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          px: 2.5,
          py: 3,
        }}
      >
        <S.Divider />
      </Box>

      <NavSection
        title={'Community'}
        titleOpacity={0.6}
        navConfig={communityNavConfig}
      />

      <Box sx={{ height: 80 }} />

      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <S.DocsWrap>
          <S.DocsImage
            src={publicImages.Sally3D.DocsGirl}
            alt='Get help! illustration'
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant='h6'>
              Get help!
            </Typography>
          </Box>

          <S.DocsButtonWrap>
            <ButtonSmallLink
              href={routes.external.docs}
              target='_blank'
              rel='noopener noreferrer'
            >
              Docs
            </ButtonSmallLink>
          </S.DocsButtonWrap>
        </S.DocsWrap>
      </Box>
    </Scrollbar>
  )

  return (
    <S.RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: S.DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: S.DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </S.RootStyle>
  )
}

export default DashboardNavbar
