import { useState, useCallback } from 'react'

type StatusCode = '500' | '403' | '404' | 'error'

export const useFetchHook = <T>(config?: { initLoading?: boolean }) => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState<boolean>(config?.initLoading ?? false)
  const [error, setError] = useState<StatusCode>() // status string or "error" for base reason

  const init = useCallback(() => {
    setData(undefined)
    setError(undefined)
    setLoading(true)
    setLoading(false)
  }, [])

  const getError = useCallback((e: { response?: { status?: number } }) => {
    if (e.response && e.response.status) {
      if ([500, 403, 404].some((it) => it === e.response?.status)) {
        return e.response.status.toString() as StatusCode
      } else {
        return 'error'
      }
    } else {
      return 'error'
    }
  }, [])

  return {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    getError,
    init,
  }
}
