import { ICollection } from './types'

export const TEST_DATA: ICollection[] = [
  // {
  //   id: '1',
  //   title: 'Bored Ape Yacht Club second edition',
  //   description: 'New BAYC. Better and more precious.',
  // },
  {
    id: '0',
    title: 'DEMO Collection',
    description: 'My first collection of 8888 images, hand drawn and unique!',
  },
]
