export enum LocalStorageKeys {
  WALLET_STATE = 'WALLET_STATE',
  SHOW_DEBUG = 'SHOW_DEBUG',
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const setLocalStorage = (key: LocalStorageKeys, value: string) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key: LocalStorageKeys) => {
  return localStorage.getItem(key) || ''
}

// functions to write / read context state

// beaware that Date object will turn into string
export const setContextToLocalStorage = (key: string, value: unknown) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.removeItem(key) // if empty value is for save - delete it from local storage
  }
}

export const getContextFromLocalStorage = <T>(
  key: LocalStorageKeys
): T | undefined => {
  const value = localStorage.getItem(key)
  return value ? (JSON.parse(value) as T) : undefined
}
