import React, { PropsWithChildren } from 'react'
import { Box, SxProps } from '@mui/material'

import * as S from './styled'

type Props = PropsWithChildren<{
  sx: SxProps
}>

const Scrollbar: React.FC<Props> = ({ children, sx, ...other }) => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    )
  }

  return (
    <S.RootStyle>
      <S.SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </S.SimpleBarStyle>
    </S.RootStyle>
  )
}

export default Scrollbar
