import { ChainId } from '@usedapp/core'

export const isLocalChain = (chainId: ChainId) =>
  chainId === ChainId.Localhost || chainId === ChainId.Hardhat

export const formatAddress = (address: string, chars: number = 18) => {
  const start = address.slice(0, chars - 6) // chars - end.length - "...".length
  const end = address.slice(address.length - 3) // last 3 chars
  return `${start}...${end}`
}
