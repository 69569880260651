import React, { useContext } from 'react'
import { Alert, Snackbar } from '@mui/material'

import { GlobalMessageContext } from 'src/context/GlobalMessageProvider'

const GlobalMessage: React.FC = () => {
  const { content, hideGlobalMessage, variant } =
    useContext(GlobalMessageContext)

  if (!content) return null

  return (
    <Snackbar
      open={true}
      autoHideDuration={6000}
      onClose={hideGlobalMessage}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        onClose={hideGlobalMessage}
        severity={variant}
        sx={{ width: '100%' }}
      >
        {content.title}
        <br />
        {content.description}
      </Alert>
    </Snackbar>
  )
}

export default GlobalMessage
