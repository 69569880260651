import React, { PropsWithChildren } from 'react'
import { ChainId } from '@usedapp/core'

import DAppProvider from './DAppProvider'
import AccountProvider from './AccountProvider'

type Props = PropsWithChildren<{
  smartContractChainId: ChainId
  localhostMulticallAddress?: string
}>

/**
 * Main provider for the dashboard app
 */
const ConsoleAppProvider: React.FC<Props> = ({
  children,
  smartContractChainId,
  localhostMulticallAddress,
}) => {
  return (
    <DAppProvider
      smartContractChainId={smartContractChainId}
      localhostMulticallAddress={localhostMulticallAddress}
    >
      <AccountProvider smartContractChainId={smartContractChainId}>
        {children}
      </AccountProvider>
    </DAppProvider>
  )
}

export default ConsoleAppProvider
