import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react'
import { useEthers, Web3Ethers, ChainId } from '@usedapp/core'

import { useLocalPersistedState, LocalStorageKeys } from '@artiffine/lib'
import { web3Modal } from './web3Modal'

enum WalletConnectionState {
  'CONNECTED' = 'connected',
  'DISCONNECTED' = 'disconnected',
}

type Props = PropsWithChildren<{
  smartContractChainId: ChainId
}>

type AccountContextType = Web3Ethers & {
  isWrongNetwork: boolean | undefined
  switchNetworkToMatchSmartContract: () => void
}

export const AccountContext = createContext<AccountContextType>({
  activateBrowserWallet: () => {},
  deactivate: () => {},
  account: undefined,
  chainId: undefined,
  active: false,
  activate: async () => {},
  setError: () => {},
  connector: undefined,
  isLoading: true,
  switchNetwork: () => {},
  switchNetworkToMatchSmartContract: () => {},
  isWrongNetwork: undefined,
})

const AccountContextProvider: React.FC<Props> = ({
  children,
  smartContractChainId,
}) => {
  const {
    activate: activateOriginal,
    activateBrowserWallet: activateBrowserWalletOriginal,
    deactivate: deactivateOriginal,
    account,
    chainId,
    switchNetwork,
    error,
    ...rest
  } = useEthers()
  const [activateError, setActivateError] = useState<Error>()

  const [walletConnetionState, setWalletConnetionState, refreshState] =
    useLocalPersistedState<WalletConnectionState>(
      LocalStorageKeys.WALLET_STATE,
      undefined
    )

  useEffect(() => {
    // refresh state if account or chainId changes
    refreshState()
  }, [account, chainId, refreshState])

  const activateBrowserWallet: typeof activateBrowserWalletOriginal =
    useCallback(
      (...args) => {
        activateBrowserWalletOriginal(...args)
        setWalletConnetionState(WalletConnectionState.CONNECTED)
      },
      [activateBrowserWalletOriginal, setWalletConnetionState]
    )

  const activate = useCallback(async () => {
    try {
      await web3Modal.clearCachedProvider()
      const provider = await web3Modal.connect()
      await activateOriginal(provider)
      setActivateError(undefined)
    } catch (error: any) {
      setActivateError(error)
      if (typeof error === 'string') {
        throw new Error(error)
      }
    }

    setWalletConnetionState(WalletConnectionState.CONNECTED)
  }, [activateOriginal, setWalletConnetionState])

  const deactivate: typeof deactivateOriginal = useCallback(
    (...args) => {
      deactivateOriginal(...args)
      setWalletConnetionState(WalletConnectionState.DISCONNECTED)
      web3Modal.clearCachedProvider()
    },
    [deactivateOriginal, setWalletConnetionState]
  )

  const switchNetworkToMatchSmartContract = useCallback(() => {
    switchNetwork(smartContractChainId)
  }, [switchNetwork, smartContractChainId])

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if ((web3Modal as any).providerController?.cachedProvider) {
        activateBrowserWallet()
      }
    }
    connectWalletOnPageLoad()
  }, [])

  const isConnected = walletConnetionState === WalletConnectionState.CONNECTED

  return (
    <AccountContext.Provider
      value={{
        activate,
        activateBrowserWallet: activate,
        deactivate,
        account: isConnected ? account : undefined,
        chainId: isConnected ? chainId : smartContractChainId,
        isWrongNetwork:
          isConnected && chainId ? chainId !== smartContractChainId : undefined,
        switchNetwork,
        switchNetworkToMatchSmartContract,
        error: activateError,
        ...rest,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountContextProvider
