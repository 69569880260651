import { Box, Stack, Typography } from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

import ProgressBar from 'src/components/ProgressBar'

import * as S from './styled'

type Props = {}

// TODO: implement correct values
const CollectionState: React.FC<Props> = () => {
  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mt: 2, mb: 2 }}
      >
        <S.StyledBox>
          <SummarizeIcon />
          <Typography variant='subtitle2'>8888</Typography>
        </S.StyledBox>

        <S.Divider />

        <S.StyledBox>
          <SupervisedUserCircleIcon />
          <Typography variant='subtitle2'>33</Typography>
        </S.StyledBox>

        <S.Divider />

        <S.StyledBox>
          <Typography variant='subtitle2'>0.2 ETH</Typography>
        </S.StyledBox>
      </Stack>
      <Box sx={{ mb: 2 }}>
        <ProgressBar value={10} />
      </Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='subtitle2' component='p'>
          Minted: 10%
        </Typography>
        <Typography variant='subtitle2' component='p'>
          Starts in: 7d
        </Typography>
      </Stack>
    </>
  )
}

export default CollectionState
