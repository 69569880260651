import { TypographyOptions } from '@mui/material/styles/createTypography'

function pxToRem(value: number) {
  return `${value / 16}rem`
}

function responsiveFontSizes({
  sm,
  md,
  lg,
}: {
  sm: number
  md: number
  lg: number
}) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  }
}

const FONT_PRIMARY = 'Inter, sans-serif'

const FONT_WEIGHT_LIGHT = 100
const FONT_WEIGHT_REGULAR = 400
const FONT_WEIGHT_MEDIUM = 500
const FONT_WEIGHT_BOLD = 700

const typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  fontWeightLight: FONT_WEIGHT_LIGHT,
  fontWeightRegular: FONT_WEIGHT_REGULAR,
  fontWeightMedium: FONT_WEIGHT_MEDIUM,
  fontWeightBold: FONT_WEIGHT_BOLD,
  h1: {
    fontWeight: FONT_WEIGHT_MEDIUM,
    lineHeight: 80 / 64,
    fontSize: pxToRem(30),
    ...responsiveFontSizes({ sm: 30, md: 30, lg: 30 }),
  },
  h2: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 64 / 48,
    fontSize: pxToRem(26),
    ...responsiveFontSizes({ sm: 26, md: 26, lg: 26 }),
  },
  h3: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  h4: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  h5: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  h6: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 28 / 18,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  subtitle1: {
    fontWeight: FONT_WEIGHT_REGULAR,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: FONT_WEIGHT_MEDIUM,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
    opacity: 0.6,
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    opacity: 0.8,
    '& > a': {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: FONT_WEIGHT_BOLD,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
}

export { typography }
