import { useAccount } from '@artiffine/core'
import { useNavigate } from 'react-router-dom'

import Banner from 'src/components/Banner'
import { routes } from 'src/constants/routes'
import { publicImages } from 'src/static/images'

import * as S from './styled'

const WelcomeBanner = () => {
  const navigate = useNavigate()
  const { account, activateBrowserWallet } = useAccount()

  return (
    <Banner
      title='Create NFT Collections in seconds'
      text={
        <>
          Start by connecting your wallet. <br />
          Need some help?{' '}
          <a
            href={routes.external.docs}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            Check out our guides
          </a>
        </>
      }
      button={
        !account
          ? {
              text: 'Connect Wallet',
              onClick: activateBrowserWallet,
            }
          : {
              text: 'Create new collection',
              onClick: () => navigate(routes.collectionCreate),
            }
      }
      image={
        <S.Img
          src={publicImages.Sally3D.RocketGirl}
          alt='Create NFT Collections in seconds'
        />
      }
      sx={{ background: '#3032a2' }}
    />
  )
}

export default WelcomeBanner
