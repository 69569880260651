import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const Logo: React.FC = () => {
  const theme = useTheme()

  console.log(theme)

  const TEXT_COLOR = theme.palette.text.primary

  return (
    <Box sx={{ width: 135, height: 28 }}>
      <svg
        width='135'
        height='28'
        viewBox='0 0 135 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_487_9862)'>
          <g filter='url(#filter0_i_487_9862)'>
            <path
              d='M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z'
              fill='url(#paint0_linear_487_9862)'
            />
          </g>
          <path
            d='M28 13.9997C28 21.7317 21.732 27.9998 14 27.9998C6.26801 27.9998 0 21.7317 0 13.9997C0 6.26773 16.5466 3.74932 14.25 8.99934C9 20.9997 15.25 24.7497 28 13.9997Z'
            fill='url(#paint1_linear_487_9862)'
            fillOpacity='0.5'
          />
          <path
            d='M28 14.0001C28 21.7321 21.732 28.0002 14 28.0002C6.26801 28.0002 0 21.7321 0 14.0001C0 7.00078 11.3966 6.65037 9.1 11.9004C3.85 23.9008 15.25 24.75 28 14.0001Z'
            fill='url(#paint2_linear_487_9862)'
            fillOpacity='0.5'
          />
          <path
            d='M28 13.9995C28 21.7315 21.732 27.9996 14 27.9996C6.26801 27.9996 0 21.7315 0 13.9995C0 10.1501 5.71556 10.8246 5.25 16.1005C4.2 27.9993 15.25 24.7495 28 13.9995Z'
            fill='url(#paint3_linear_487_9862)'
            fillOpacity='0.5'
          />
          <path
            d='M41.5253 22.0005H37.5708L43.5964 4.54599H48.3521L54.3691 22.0005H50.4146L46.0424 8.53462H45.906L41.5253 22.0005ZM41.2782 15.1397H50.6191V18.0204H41.2782V15.1397Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M56.4018 22.0005V8.90962H59.9217V11.1937H60.058C60.2967 10.3812 60.6972 9.76758 61.2597 9.35281C61.8222 8.93235 62.4699 8.72212 63.2029 8.72212C63.3847 8.72212 63.5807 8.73349 63.791 8.75621C64.0012 8.77894 64.1859 8.81019 64.3449 8.84996V12.0716C64.1745 12.0204 63.9387 11.975 63.6376 11.9352C63.3364 11.8954 63.0609 11.8755 62.8109 11.8755C62.2768 11.8755 61.7995 11.992 61.379 12.225C60.9643 12.4522 60.6347 12.7704 60.3904 13.1795C60.1518 13.5886 60.0324 14.0602 60.0324 14.5943V22.0005H56.4018Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M73.8414 8.90962V11.6369H65.9579V8.90962H73.8414ZM67.7476 5.77326H71.3783V17.9778C71.3783 18.313 71.4295 18.5744 71.5317 18.7619C71.634 18.9437 71.7761 19.0716 71.9579 19.1454C72.1454 19.2193 72.3613 19.2562 72.6056 19.2562C72.7761 19.2562 72.9465 19.242 73.117 19.2136C73.2874 19.1795 73.4181 19.1539 73.509 19.1369L74.08 21.8386C73.8982 21.8954 73.6425 21.9608 73.313 22.0346C72.9834 22.1142 72.5829 22.1625 72.1113 22.1795C71.2363 22.2136 70.4692 22.0971 69.8102 21.8301C69.1567 21.563 68.6482 21.1483 68.2846 20.5858C67.9209 20.0233 67.742 19.313 67.7476 18.4551V5.77326Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M76.2065 22.0005V8.90962H79.8371V22.0005H76.2065ZM78.0303 7.22212C77.4905 7.22212 77.0275 7.04315 76.6411 6.68519C76.2604 6.32156 76.0701 5.8869 76.0701 5.38121C76.0701 4.88121 76.2604 4.45224 76.6411 4.09428C77.0275 3.73065 77.4905 3.54883 78.0303 3.54883C78.5701 3.54883 79.0303 3.73065 79.411 4.09428C79.7974 4.45224 79.9905 4.88121 79.9905 5.38121C79.9905 5.8869 79.7974 6.32156 79.411 6.68519C79.0303 7.04315 78.5701 7.22212 78.0303 7.22212Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M89.7853 8.90962V11.6369H81.7057V8.90962H89.7853ZM83.5552 22.0005V7.9636C83.5552 7.01474 83.7398 6.22781 84.1092 5.60281C84.4842 4.97781 84.9955 4.50906 85.6432 4.19656C86.291 3.88406 87.0268 3.72781 87.8506 3.72781C88.4074 3.72781 88.916 3.77042 89.3762 3.85565C89.8421 3.94087 90.1887 4.01758 90.416 4.08576L89.7682 6.81303C89.6262 6.76758 89.4501 6.72496 89.2398 6.68519C89.0353 6.64542 88.8251 6.62553 88.6092 6.62553C88.0751 6.62553 87.7029 6.75053 87.4927 7.00053C87.2824 7.24485 87.1773 7.5886 87.1773 8.03178V22.0005H83.5552Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M99.0431 8.90962V11.6369H90.9636V8.90962H99.0431ZM92.813 22.0005V7.9636C92.813 7.01474 92.9976 6.22781 93.367 5.60281C93.742 4.97781 94.2533 4.50906 94.9011 4.19656C95.5488 3.88406 96.2846 3.72781 97.1084 3.72781C97.6653 3.72781 98.1738 3.77042 98.634 3.85565C99.0999 3.94087 99.4465 4.01758 99.6738 4.08576L99.0261 6.81303C98.884 6.76758 98.7079 6.72496 98.4976 6.68519C98.2931 6.64542 98.0829 6.62553 97.867 6.62553C97.3329 6.62553 96.9607 6.75053 96.7505 7.00053C96.5403 7.24485 96.4352 7.5886 96.4352 8.03178V22.0005H92.813Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M101.261 22.0005V8.90962H104.892V22.0005H101.261ZM103.085 7.22212C102.545 7.22212 102.082 7.04315 101.696 6.68519C101.315 6.32156 101.125 5.8869 101.125 5.38121C101.125 4.88121 101.315 4.45224 101.696 4.09428C102.082 3.73065 102.545 3.54883 103.085 3.54883C103.625 3.54883 104.085 3.73065 104.466 4.09428C104.852 4.45224 105.045 4.88121 105.045 5.38121C105.045 5.8869 104.852 6.32156 104.466 6.68519C104.085 7.04315 103.625 7.22212 103.085 7.22212Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M111.431 14.4324V22.0005H107.8V8.90962H111.26V11.2193H111.414C111.704 10.4579 112.189 9.85565 112.871 9.41246C113.553 8.9636 114.38 8.73917 115.351 8.73917C116.26 8.73917 117.053 8.93803 117.729 9.33576C118.405 9.73349 118.931 10.3017 119.306 11.0403C119.681 11.7733 119.868 12.6483 119.868 13.6653V22.0005H116.238V14.313C116.243 13.5119 116.039 12.8869 115.624 12.438C115.209 11.9835 114.638 11.7562 113.911 11.7562C113.422 11.7562 112.991 11.8613 112.616 12.0716C112.246 12.2818 111.956 12.5886 111.746 12.992C111.542 13.3897 111.437 13.8699 111.431 14.4324Z'
            fill={TEXT_COLOR}
          />
          <path
            d='M128.696 22.2562C127.349 22.2562 126.19 21.9835 125.219 21.438C124.253 20.8869 123.508 20.1085 122.986 19.1028C122.463 18.0914 122.201 16.8954 122.201 15.5147C122.201 14.1681 122.463 12.9863 122.986 11.9693C123.508 10.9522 124.244 10.1596 125.193 9.59144C126.148 9.02326 127.267 8.73917 128.551 8.73917C129.415 8.73917 130.219 8.87837 130.963 9.15678C131.713 9.42951 132.366 9.84144 132.923 10.3926C133.486 10.9437 133.923 11.6369 134.236 12.4721C134.548 13.3017 134.704 14.2733 134.704 15.3869V16.3841H123.65V14.1341H131.287C131.287 13.6113 131.173 13.1483 130.946 12.7449C130.719 12.3414 130.403 12.0261 130 11.7988C129.602 11.5659 129.139 11.4494 128.611 11.4494C128.059 11.4494 127.571 11.5772 127.145 11.8329C126.724 12.0829 126.395 12.421 126.156 12.8471C125.917 13.2676 125.795 13.7363 125.79 14.2534V16.3926C125.79 17.0403 125.909 17.6 126.148 18.0716C126.392 18.5431 126.736 18.9068 127.179 19.1625C127.622 19.4181 128.148 19.546 128.755 19.546C129.159 19.546 129.528 19.4892 129.863 19.3755C130.199 19.2619 130.486 19.0914 130.724 18.8642C130.963 18.6369 131.145 18.3585 131.27 18.0289L134.628 18.2505C134.457 19.0574 134.108 19.7619 133.579 20.3642C133.057 20.9608 132.38 21.4267 131.551 21.7619C130.727 22.0914 129.775 22.2562 128.696 22.2562Z'
            fill={TEXT_COLOR}
          />
        </g>
        <defs>
          <filter
            id='filter0_i_487_9862'
            x='0'
            y='0'
            width='28'
            height='28'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='BackgroundImageFix'
              result='shape'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset />
            <feGaussianBlur stdDeviation='2' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
            />
            <feBlend
              mode='normal'
              in2='shape'
              result='effect1_innerShadow_487_9862'
            />
          </filter>
          <linearGradient
            id='paint0_linear_487_9862'
            x1='1.5'
            y1='6.50001'
            x2='25.5'
            y2='22'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FA4078' />
            <stop offset='1' stopColor='#484CFC' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_487_9862'
            x1='21'
            y1='9.75'
            x2='14'
            y2='27.9998'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset='1' stopColor='white' stopOpacity='0.3' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_487_9862'
            x1='21'
            y1='9.75059'
            x2='14'
            y2='28.0004'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset='1' stopColor='white' stopOpacity='0.3' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_487_9862'
            x1='21'
            y1='9.74999'
            x2='14'
            y2='27.9998'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='white' />
            <stop offset='1' stopColor='white' stopOpacity='0.3' />
          </linearGradient>
          <clipPath id='clip0_487_9862'>
            <rect width='135' height='28' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default Logo
