import { styled } from '@mui/material/styles'
import { CardMedia, CardActionArea } from '@mui/material'

export const CardMediaImg = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: 180,
}))

export const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& > *': {
    width: '100%',
  },
}))
