import { styled } from '@mui/material/styles'
import { CardMedia, CardActionArea, Box } from '@mui/material'

export const CardMediaImg = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  height: 180,
}))

export const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  height: '100%',

  padding: theme.spacing(2),
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px dashed ${theme.palette.grey[600]}`,
  height: '100%',
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))

export const Plus = styled('div')(({ theme }) => ({
  fontSize: 100,
  marginBottom: theme.spacing(4),
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.grey[600],
}))
