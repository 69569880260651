import React, { useContext, useEffect } from 'react'
import { useEtherBalance } from '@usedapp/core'

import { useAccount } from '@artiffine/core'

import { GlobalMessageContext } from 'src/context/GlobalMessageProvider'

import * as S from './styled'
import AccountState from './AccountState'

const MetamaskButton: React.FC = () => {
  const { activateBrowserWallet, account, error, deactivate } = useAccount()
  const { showGlobalMessage } = useContext(GlobalMessageContext)
  const etherBalance = useEtherBalance(account)

  useEffect(() => {
    if (
      error?.message?.includes('wallet_requestPermissions') ||
      error?.message === 'User Rejected'
    ) {
      showGlobalMessage(
        {
          title: 'Confirmation required in your browser wallet',
          description: 'Try again or cancel any pending requests',
        },
        'error'
      )
    }
  }, [error, showGlobalMessage])

  const connectWallet = () => {
    activateBrowserWallet()
  }

  return account ? (
    <AccountState
      account={account}
      etherBalance={etherBalance}
      onDisconnectClick={() => deactivate()}
    />
  ) : (
    <S.StyledButton onClick={connectWallet}>Connect Wallet</S.StyledButton>
  )
}

export default MetamaskButton
