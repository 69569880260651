import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { ConsoleAppProvider } from '@artiffine/core'

import GlobalStyles from 'src/styles/globalStyles'
import { darkTheme } from 'src/styles/styledTheme'

import GlobalMessageProvider from 'src/context/GlobalMessageProvider'
import GlobalMessage from 'src/containers/GlobalMessage'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'

// scroll bar
import 'simplebar/src/simplebar.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst={true}>
        <MUIThemeProvider theme={darkTheme}>
          <StyledThemeProvider theme={darkTheme}>
            <GlobalMessageProvider>
              <ConsoleAppProvider smartContractChainId={1}>
                <App />
              </ConsoleAppProvider>
              <GlobalMessage />
            </GlobalMessageProvider>
            <GlobalStyles />
          </StyledThemeProvider>
        </MUIThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
