// web3 modal providers
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'

// see https://github.com/Web3Modal/web3modal#provider-options
const providerOptions = {
  injected: {
    display: {
      name: 'Metamask',
      description: 'Connect with the provider in your Browser',
    },
    package: null,
  },
  // walletconnect: {
  //   package: WalletConnectProvider,
  //   options: {
  //     infuraId: 'INFURA_ID', // TODO: add infura ID // // Required
  //   },
  // },
  // coinbasewallet: {
  //   package: CoinbaseWalletSDK, // Required
  //   options: {
  //     appName: 'My Awesome App', // Required
  //     infuraId: 'INFURA_ID', // TODO: add infura ID // Required
  //     // rpc: '', // Optional if `infuraId` is provided; otherwise it's required
  //     chainId: smartContractChainId, // Optional. It defaults to 1 if not provided
  //     darkMode: false, // Optional. Use dark theme, defaults to false
  //   },
  // },
  //   binancechainwallet: {
  //     package: true,
  //   },
}

let web3Modal: Web3Modal
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions, // required
  })
}

export { web3Modal }
