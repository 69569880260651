//

import Paper from './Paper'

// ----------------------------------------------------------------------

export default function ComponentsOverrides() {
  return {
    ...Paper(),
  }
}
