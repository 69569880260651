import { styled } from '@mui/material/styles'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

export const Wrapper = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

export const MainWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 32,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export const MobileOnly = styled('div')(({ theme }) => ({
  background: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  textAlign: 'center',
  padding: theme.spacing(2),
  marginTop: '-' + theme.spacing(3),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
