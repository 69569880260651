import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { routes } from 'src/constants/routes'

import * as S from './styled'

type Props = {}

const CreateCollection: React.FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <Link to={routes.collectionCreate}>
      <Card sx={{ height: '100%' }}>
        <S.StyledCardActionArea>
          <S.StyledBox>
            <S.Plus>+</S.Plus>
            <Typography variant='body2'>
              {t('myCollections.createNew')}
            </Typography>
          </S.StyledBox>
        </S.StyledCardActionArea>
      </Card>
    </Link>
  )
}

export default CreateCollection
