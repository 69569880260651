import React from 'react'
import { IconButton, Box, Stack } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import GasPrice from 'src/containers/GasPrice'
import MetamaskButton from 'src/containers/MetamaskButton'

import * as S from './styled'

type Props = {
  onOpenSidebar: () => void
}

const DashboardNavbar: React.FC<Props> = ({ onOpenSidebar }) => {
  return (
    <S.RootStyle>
      <S.ToolbarStyle>
        <Box>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
            aria-label='Menu'
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box>
          <Stack direction='row'>
            <GasPrice />
            <MetamaskButton />
          </Stack>
        </Box>
      </S.ToolbarStyle>
    </S.RootStyle>
  )
}

export default DashboardNavbar
