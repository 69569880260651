import { useState, useEffect } from 'react'

type Props = {
  targetTimestamp: number
  onTimeChange?: (time: number) => void
}

/**
 * Couter up or down
 * The onTimeChange is called immediately and then with every change
 * @param initTime: ms to start with
 */
export const useCountdownTimer = ({ targetTimestamp, onTimeChange }: Props) => {
  const [diff, setDiff] = useState(targetTimestamp - Date.now())

  useEffect(() => {
    const timer = setInterval(() => {
      setDiff(targetTimestamp - Date.now())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [targetTimestamp])

  useEffect(() => {
    if (onTimeChange) {
      onTimeChange(diff)
    }
  }, [diff, onTimeChange])

  return { diff }
}
