import { matchPath } from 'react-router-dom'

const privateRoutes = {
  collectionDetail: `/collections/detail/:id`,
  collectionCreate: `/collections/create`,
  // tmp
  styleguide: '/styleguide',
}

export const routesFactory = {
  collectionDetail: (id: string) =>
    privateRoutes.collectionDetail.replace(':id', id),
}

const publicRoutes = {
  index: '/',
}

const externalRoutes = {
  docs: 'https://docs.artiffine.com/',
  blog: 'https://artiffine.com/blog',
  twitter: 'https://twitter.com/artiffine',
  landing: {
    contract: 'https://www.artiffine.com/#Get-in-touch',
  },
}

const routes = { ...privateRoutes, ...publicRoutes, external: externalRoutes }

export const isRoutePrivate = (path: string | readonly string[]) => {
  if (typeof path === 'string') {
    return !!Object.values(privateRoutes).find(
      (r) => !!matchPath({ path: r }, path)
    )
  } else {
    return path.some(
      (it) =>
        !!Object.values(privateRoutes).find((r) => !!matchPath({ path: r }, it))
    )
  }
}

export const getRouteIndex = (route: string) => {
  return Object.values(routes)
    .findIndex((it) => {
      return it === route
    })
    .toString()
}

export { routes }
