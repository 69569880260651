import React from 'react'

interface IState {
  error: any
}

interface IProps {
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<IProps, IState> {
  public state = { error: null }
  private unlisten = () => {}

  componentWillUnmount() {
    this.unlisten()
  }

  public componentDidCatch(error: Error | null, errorInfo: any) {
    this.setState({ error })
    // TODO: connect to Sentry or other error reporting service
    // reportError(error, errorInfo)
  }

  public render() {
    if (this.state.error) {
      // render fallback UI
      return 'There was an error'
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default ErrorBoundary
