import { styled } from '@mui/material/styles'

export const Wrap = styled('div')(({ theme }) => ({
  background: '#434557',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 4),
  borderRadius: theme.shape.borderRadius,
  height: '50.2px',
  minWidth: 264,
  cursor: 'pointer',
}))

export const Account = styled('span')(({ theme }) => ({
  ...(theme.typography.caption as any),
  fontWeight: theme.typography.fontWeightBold,
  opacity: 0.8,
  width: '100%',
}))

export const Balance = styled('span')(({ theme }) => ({
  ...(theme.typography.caption as any),
  fontWeight: theme.typography.fontWeightBold,
  width: '100%',
}))

export const Disconnect = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}))
