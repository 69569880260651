import { useEffect } from 'react'
import { CircularProgress, Grid, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAllCollectionsData } from 'src/hooks/useAllCollectionsData'

import Thumbnail from './Thumbnail'
import CreateCollection from './CreateCollection'

const MyCollections = () => {
  const { t } = useTranslation()

  const { data, loading, load } = useAllCollectionsData()

  useEffect(() => {
    load()
  }, [load])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h1'>{t('myCollections.title')}</Typography>
      </Grid>
      {loading && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      )}
      {data?.map((collection) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={collection.id}>
          <Thumbnail data={collection} />
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <CreateCollection />
      </Grid>
    </Grid>
  )
}

export default MyCollections
