import { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box } from '@mui/material'

type Props = PropsWithChildren<{
  title: string
}>

const Page: React.FC<Props> = ({ title, children }) => (
  <>
    <Helmet>
      <title>{`${title} | Artiffine`}</title>
    </Helmet>

    <Box>{children}</Box>
  </>
)

export default Page
