import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardContent, Typography, Box } from '@mui/material'

import { ICollection } from 'src/api'
import { routesFactory } from 'src/constants/routes'
import { publicImages } from 'src/static/images'

import * as S from './styled'
import CollectionState from './CollectionState'

type Props = {
  data: ICollection
}

const Thumbnail: React.FC<Props> = ({ data }) => {
  return (
    <Link to={routesFactory.collectionDetail(data.id)}>
      <Card sx={{ height: '100%' }}>
        <S.StyledCardActionArea>
          <Box sx={{ px: 2, py: 2 }}>
            <S.CardMediaImg image={publicImages.CollectionPlaceholder} />
          </Box>
          <CardContent
            sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            <Box sx={{ flex: 1, mb: 2 }}>
              <Typography gutterBottom variant='h5' component='h2'>
                {data.title}
              </Typography>
              <Typography variant='body2'>{data.description}</Typography>
            </Box>
            <CollectionState />
          </CardContent>
        </S.StyledCardActionArea>
      </Card>
    </Link>
  )
}

export default Thumbnail
