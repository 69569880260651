import { Container, Typography } from '@mui/material'

import Page from 'src/components/Page'
import DashboardLayout from 'src/components/DashboardLayout'

const StyleguidePage = () => {
  return (
    <DashboardLayout>
      <Page title='Styleguide'>
        <Container maxWidth='xl'>
          {(
            [
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'subtitle1',
              'subtitle2',
              'body1',
              'body2',
              'caption',
              'button',
              'overline',
              'inherit',
            ] as any
          ).map((variant: any) => (
            <Typography
              variant={variant}
              sx={{ display: 'block' }}
              key={variant}
            >
              This is how {variant} looks like
            </Typography>
          ))}
        </Container>
      </Page>
    </DashboardLayout>
  )
}

export default StyleguidePage
