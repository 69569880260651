import React, { createContext, PropsWithChildren } from 'react'
import { BigNumber } from 'ethers'
import { ChainId } from '@usedapp/core'

import { isNumber } from '@artiffine/lib'

import { contractFactory, ContractInterface } from '../../contractFactory'

import { useContractData } from './useContractData'

type Props = PropsWithChildren<{
  smartContractAddress: string
  smartContractChainId: ChainId
}>

type SmartContractContextType = {
  name: string | undefined
  symbol: string | undefined
  totalSupply: number | undefined
  maxSupply: number | undefined
  pricePerToken: BigNumber | undefined
  isSaleActive: boolean | undefined
  isWhitelistSaleActive: boolean | undefined
  maxMint: number | undefined
  smartContractAddress: string
  smartContractChainId: number
  contract: ContractInterface
  owner: string | undefined
}

export const SmartContractContext = createContext<SmartContractContextType>({
  name: undefined,
  symbol: undefined,
  totalSupply: undefined,
  maxSupply: undefined,
  pricePerToken: undefined,
  isSaleActive: false,
  isWhitelistSaleActive: false,
  maxMint: undefined,
  smartContractAddress: '',
  smartContractChainId: ChainId.Mainnet,
  contract: undefined as any,
  owner: undefined,
})

const SmartContractProvider: React.FC<Props> = ({
  children,
  smartContractAddress,
  smartContractChainId,
}) => {
  const contract = contractFactory.construct(smartContractAddress)

  const {
    name,
    symbol,
    isSaleActive,
    isWhitelistSaleActive,
    pricePerToken,
    owner,
    ...restData
  } = useContractData({ contract })

  const totalSupply = restData.totalSupply?.toNumber()
  const maxSupply = restData.maxSupply?.toNumber()

  const mintLeft =
    isNumber(maxSupply) && isNumber(totalSupply)
      ? maxSupply - totalSupply
      : undefined
  const maxMintNumber = restData.maxMint?.toNumber()
  const maxMint =
    isNumber(maxMintNumber) && isNumber(mintLeft) && maxMintNumber < mintLeft
      ? maxMintNumber
      : mintLeft

  return (
    <SmartContractContext.Provider
      value={{
        name,
        symbol,
        totalSupply,
        maxSupply,
        pricePerToken,
        isSaleActive,
        isWhitelistSaleActive,
        maxMint,
        smartContractAddress,
        smartContractChainId,
        contract,
        owner,
      }}
    >
      {children}
    </SmartContractContext.Provider>
  )
}

export default SmartContractProvider
