import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAccount } from '@artiffine/core'

import Page from 'src/components/Page'
import DashboardLayout from 'src/components/DashboardLayout'

import WelcomeBanner from './WelcomeBanner'
import MyCollections from './MyCollections'

const DashboardPage = () => {
  const { t } = useTranslation()
  const { account } = useAccount()

  return (
    <DashboardLayout>
      <Page title={t('dashboard.pageTitle')}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <WelcomeBanner />
          </Grid>
          <Grid item xs={12}>
            {account && <MyCollections />}
          </Grid>
        </Grid>
      </Page>
    </DashboardLayout>
  )
}

export default DashboardPage
