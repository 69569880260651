import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 1),

  '& > svg': {
    paddingRight: theme.spacing(1),
    opacity: 0.6,
  },
}))

export const Divider = styled('div')(({ theme }) => ({
  width: 2,
  height: 20,
  background: '#393B4D',
}))
