import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography, Container, Box, Stack } from '@mui/material'

import Page from 'src/components/Page'
import { routes } from 'src/constants/routes'
import { publicImages } from 'src/static/images'

import * as S from './styled'

const PageSoonOnline: React.FC = () => {
  return (
    <Page title='Request early access'>
      <Container>
        <S.ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant='h1' paragraph>
            Request early access!
          </Typography>

          <Typography variant='body2'>
            Artiffine = The fastest and easiest way to create and sell NFT
            collections.
            <br />
            <br />
            <strong>Apply for early access now!</strong>
          </Typography>

          <Box
            component='img'
            src={publicImages.Sally3D.Dog}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '80%' }}
            gap={2}
          >
            <Button
              to={routes.index}
              size='large'
              variant='outlined'
              fullWidth={true}
              component={RouterLink}
            >
              Go back
            </Button>

            <Button
              size='large'
              variant='contained'
              fullWidth={true}
              component={'a'}
              href={routes.external.landing.contract}
              target='_blank'
            >
              Apply here
            </Button>
          </Stack>
        </S.ContentStyle>
      </Container>
    </Page>
  )
}

export default PageSoonOnline
