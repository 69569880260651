import { styled } from '@mui/material/styles'
import { Box, LinearProgress } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#1E1E2C',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}))

type Props = {
  value: number
}

const ProgressBar = ({ value }: Props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <BorderLinearProgress
        variant='determinate'
        value={value}
        aria-label='Minted %'
      />
    </Box>
  )
}

export default ProgressBar
