import React, { useState, createContext, useEffect, useCallback } from 'react'
import { PropsWithChildren } from 'react'

export type MessageVariants = 'error' | 'success' | 'warning' | 'info'

export const GlobalMessageContext = createContext({
  content: null as IGlobalMessageContent | null,
  variant: 'success' as MessageVariants,
  hideGlobalMessage: () => {},
  showGlobalMessage: (
    content: {
      title: string
      description?: string
    },
    variant: MessageVariants,
    config?: IGlobalMessageConfig
  ) => {},
})

interface IGlobalMessageConfig {
  timeout?: number
}

interface IGlobalMessageContent {
  title: string
  description?: string
}

const defaultConfig: IGlobalMessageConfig = {
  timeout: 5 * 1000,
}

type Props = PropsWithChildren<{}>

const GlobalMessageProvider: React.FC<Props> = ({ children }) => {
  const [content, setContent] = useState<IGlobalMessageContent | null>(null)
  const [config, setConfig] = useState<IGlobalMessageConfig>(defaultConfig)
  const [variant, setVariant] = useState<MessageVariants>('success')

  // hide banners after X ms
  useEffect(() => {
    if (!content) {
      return
    }
    const { timeout } = config
    if (timeout === 0) {
      // keep the banner forever
      return
    }
    const timer = setTimeout(() => {
      setContent(null)
      setConfig(defaultConfig)
    }, timeout)
    return () => clearTimeout(timer)
  }, [content, config])

  const hideGlobalMessage = useCallback(() => {
    setContent(null)
    setConfig(defaultConfig)
  }, [])

  const showGlobalMessage = useCallback(
    (
      content: {
        title: string
        description?: string
      },
      variant: MessageVariants,
      config?: IGlobalMessageConfig
    ) => {
      setContent(content)
      setVariant(variant)
      setConfig({ ...defaultConfig, ...config })
    },
    []
  )

  return (
    <GlobalMessageContext.Provider
      value={{
        content,
        hideGlobalMessage,
        showGlobalMessage,
        variant,
      }}
    >
      {children}
    </GlobalMessageContext.Provider>
  )
}

export default GlobalMessageProvider
