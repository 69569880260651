import styled from '@emotion/styled'

export const DRAWER_WIDTH = 280

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}))

export const DocsWrap = styled.div`
  background: #2d2e40;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 70px;
  border-radius: ${({ theme }) => Number(theme.shape.borderRadius) * 2}px;
  width: 60%;
  margin-top: 60px;
`

export const DocsImage = styled.img`
  height: auto;
  width: 80px;
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
`

export const DocsButtonWrap = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.palette.text.primary};
  opacity: 0.1;
`
