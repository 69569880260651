import { matchPath, useLocation } from 'react-router-dom'
import { Box, List, Typography } from '@mui/material'

import NavItem, { NavItemType } from './NavItem'

type Props = {
  title: string
  titleOpacity?: number
  navConfig: Array<NavItemType>
}

const NavSection: React.FC<Props> = ({ title, titleOpacity, navConfig }) => {
  const { pathname } = useLocation()

  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  return (
    <Box
      sx={{
        px: 3,
      }}
    >
      <Typography
        variant='subtitle1'
        sx={{
          opacity: titleOpacity ?? 1,
        }}
      >
        {title}
      </Typography>
      <List>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  )
}

export default NavSection
