import React, { useState } from 'react'
import { BigNumber } from 'ethers'
import { formatEther } from '@ethersproject/units'

import { formatAddress } from '@artiffine/lib'

import * as S from './styled'

type Props = {
  account: string | null | undefined
  etherBalance: BigNumber | undefined
  onDisconnectClick: () => void
}

const AccountState: React.FC<Props> = ({
  account,
  etherBalance,
  onDisconnectClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <S.Wrap
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={isHovered ? onDisconnectClick : undefined}
    >
      {isHovered ? (
        <S.Disconnect>Disconnect</S.Disconnect>
      ) : (
        <>
          <S.Account>{account ? formatAddress(account) : '---'}</S.Account>
          <S.Balance>
            {etherBalance !== undefined
              ? `${formatEther(etherBalance)} ETH`
              : '---'}
          </S.Balance>
        </>
      )}
    </S.Wrap>
  )
}

export default AccountState
