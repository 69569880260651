import { TEST_DATA } from './data'

// TODO: implement correct API
class CollectionsClient {
  getAll = async () => ({ data: TEST_DATA })

  getById = async ({ id }: { id: string }) => {
    const item = TEST_DATA.find((c) => c.id === id)
    if (item) {
      return { data: item }
    } else {
      throw Error('Error 404, item not found')
    }
  }
}

export { CollectionsClient }
