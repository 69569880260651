import styled, { css } from 'styled-components'

const ButtonStyles = css<{ onClick?: unknown; type?: string }>`
  padding: 14px 32px;

  font-size: 1rem;
  line-height: 1.4rem;

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: linear-gradient(270deg, #fa4078, #484cfc);
  color: white;
  border: none;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};

  transition: opacity 100ms linear;

  ${({ onClick, type }) =>
    (!!onClick || type === 'submit') &&
    css`
      &:hover {
        opacity: 0.7;
      }
    `};
`

type Props = {
  variant?: 'primary' | 'secondary'
}

export const Button = styled.button<Props>`
  ${ButtonStyles};
  cursor: ${({ onClick, type }) =>
    !!onClick || type === 'submit' ? 'pointer' : 'initial'};

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background: rgba(256, 256, 256, 0.2);
    `}
`

const ButtonSmallStyles = css`
  padding: 8px;

  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.info.contrastText};
  background-color: ${({ theme }) => theme.palette.info.main};
  border: none;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const ButtonSmall = styled.button`
  ${ButtonSmallStyles};
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'initial')};
`

export const ButtonSmallLink = styled.a`
  display: inline-block;
  ${ButtonSmallStyles};
  cursor: pointer;
  text-decoration: none;
`

export const ButtonLink = styled.a<{ variant?: 'ghost' }>`
  display: inline-block;
  ${ButtonStyles};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  ${({ variant, theme }) =>
    variant === 'ghost' &&
    css`
      border: 2px solid ${theme.palette.primary.main};
    `}
`
